import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  Link as ScrollLink,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

import Layout from "../components/Layout";

import hero_video from "../video/hero.mp4";
import AudioPlayer from "../components/AudioPlayer";

import music_wave from "../img/music.svg";

import shopify from "../img/shopify.svg";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Parallax } from "react-parallax";

import { format } from "date-fns";

import piyush from "../img/team/pr.jpg";
import ankit from "../img/team/ag.jpg";
import pritam from "../img/team/pd.jpg";
import ritwik from "../img/team/rs.png";

import band_1 from "../img//band-1.jpg";
import band_2 from "../img//band-2.jpg";

import aarlon from "../img/aarlon.png";

import upper_img from "../img/p-1.jpg";
import lower_img from "../img/lower.jpg";

// eslint-disable-next-line
export const IndexPageTemplate = ({ data }) => {
  var merch_store = data.merch_store.edges;

  // normalize merch_store
  merch_store = merch_store.map((item) => {
    return {
      id: item.node.id,
      slug: item.node.fields.slug,
      title: item.node.frontmatter.title,
      link: item.node.frontmatter.link,
      image: getImage(item.node.frontmatter.image),
    };
  });

  var tour = data.tour.edges;

  // normalize tour
  tour = tour.map((item) => {
    return {
      id: item.node.id,
      slug: item.node.fields.slug,
      title: item.node.frontmatter.title,
      location: item.node.frontmatter.location,
      link: item.node.frontmatter.link,
      date: format(new Date(item.node.frontmatter.date), "dd MMM yyyy"),
    };
  });

  var latestAlbum = data.home_data.frontmatter.latestAlbum;

  var latestSingle = data.home_data.frontmatter.latestSingle;

  var latestSong = data.home_data.frontmatter.latestSong;

  const formatDate = (date) => {
    return format(new Date(date), "dd MMM yyyy");
  };

  return (
    <>
      <section className="home-section">
        <div className="hero-video-wp">
          <video
            src={hero_video}
            autoPlay
            muted 
            loop
            playsInline
            className="hero-video"
          />
        </div>
        <div className="social-links">
          <a
            href="https://www.instagram.com/aarlonofficial/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="ri-instagram-line"></i>
          </a> 
          <a
            href="https://www.facebook.com/aarlonofficial/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="ri-facebook-line"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UC9YU7OoBqXiXH8ZF534GQ1A"
            target="_blank"
            rel="noreferrer"
          >
            <i className="ri-youtube-line"></i>
          </a>
          <a
            href="https://open.spotify.com/artist/1PIugjYFpCooJJmYMqn8kR"
            target="_blank"
            rel="noreferrer"
          >
            <i className="ri-spotify-line"></i>
          </a>
          <a
            href="https://music.apple.com/in/artist/aarlon/1613153198"
            target="_blank"
            rel="noreferrer"
          >
            <i className="ri-apple-fill"></i> Music
          </a>
        </div>
        <div className="audio-player">
          <div className="alumb-art">
            <GatsbyImage
              image={latestSong.cover.childImageSharp.gatsbyImageData}
              alt="cover"
            />
          </div>
          <AudioPlayer
            songURL={latestSong.file.publicURL}
            name={latestSong.title}
          />
        </div>
        <div className="home-wrapper">
          <h3>अंत ही आरंभ है</h3>
          <div className="aarlon-logo">
            <img src={aarlon} alt="aarlon" />
          </div>
          <div className="btn-wrap">
            <ScrollLink
              className="btn btn-primary"
              to="store"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Buy Merch / Album
            </ScrollLink>

            <ScrollLink
              className="btn btn-secondary"
              to="tours"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Tours
            </ScrollLink>
          </div>
        </div>
      </section>

      <section className="about-section" id="about">
        <div className="about-wrapper">
          <div className="about-content">
            <div className="title-wp">
              <div className="music-wave">
                <img src={music_wave} alt="music_wave" />
              </div>
              <h2 className="section-title">About "Aarlon"</h2>
              <h3>अंत ही आरंभ है</h3>
            </div>
            <p>
              Aarlon is an Indian alternative/metalcore band formed in 2017. The
              band's lineup consists of Ritwik Shivam (guitar), Piyush Rana
              (guitar), Ankit Ganguly (bass) and Pritam Adhikary (vocals). The
              band writes in Hindi and their soundscape is characterized by a
              melancholic blast of metalcore with post-rock touches. It utilizes
              melancholic clean vocals and wrathful screams which lay on a vast
              bed of power chords and reverb-soaked synthesizers.
            </p>
          </div>
          <div className="about-image">
            <img src={band_1} alt="about" />
          </div>
        </div>
        <div className="about-team-wrapper">
          <Splide
            options={{
              rewind: true,
              arrows: true,
              pagination: false,
              gap: "24px",
              
            }}
            aria-label="React Splide Example"
          >
            <SplideSlide>
              <div className="team-card">
                <div className="team-image">
                  <img src={piyush} alt="about" />
                </div>
                <div className="team-content">
                  <h3>Piyush Rana</h3>
                  <p>Lyrics, Guitar</p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="team-card">
                <div className="team-image">
                  <img src={pritam} alt="about" />
                </div>
                <div className="team-content">
                  <h3>Pritam Adhikary</h3>
                  <p>Vocals</p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="team-card">
                <div className="team-image">
                  <img src={ankit} alt="about" />
                </div>
                <div className="team-content">
                  <h3>Ankit Gangulary</h3>
                  <p>Bass</p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="team-card">
                <div className="team-image">
                  <img src={ritwik} alt="about" />
                </div>
                <div className="team-content">
                  <h3>Ritwik Shivam</h3>
                  <p>Guitars, Production</p>
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>

      <section className="youtube-section">
        <div className="big-video">
          <iframe
            src="https://www.youtube.com/embed/9on-B1AAHLc?si=Bnxkrt1fW6UtQWlJ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="youtube-wrapper">
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/6iKOgbfNnmM?si=vv1P9tuIcsbiMQv5"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/fsWvXYt8-BU?si=7aZXCzWKcvoK6XM5"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/V__Y555Lb6U?si=2HSjbqP4W0krHewl"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/J4Mv1uDKTBc?si=qaV5Amdz1XkwZCnq"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/5U2Tb-1vHeI?si=wRR2AlqOZLKhlKaQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/GgRX8jneDo8?si=BP06yjVm5dUWuNKv"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/gBq8xWha_uE?si=pVsjubfKkvWpUVvS"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="youtube-card">
            <iframe
              src="https://www.youtube.com/embed/MCt8leHp_HM?si=PR77WSE9LkNv2txV"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      <section className="store-section" id="store">
        <div className="title-wp">
          <div className="music-wave">
            <img src={music_wave} alt="music_wave" />
          </div>
          <h2 className="section-title">Aarlon's store</h2>
          <p>Shop the trend at our merch store – your go-to for cool, exclusive gear that lets you wear your passions.</p>
          {/* <a
            className="shopify-btn"
            href="https://shop.aarlon.in/"
            target="_blank"
            rel="noreferrer"
            style={{ marginTop: "24px", padding: "16px 24px"  }}
          >
            <img src={shopify} alt="Shopify" />
            Shopify Store
          </a> */}
        </div>

        <div className="store-wrap">
          <Splide
            options={{
              arrows: true,
              pagination: true,
              gap: "24px",
            }}
            aria-label="React Splide Example"
          >
            {merch_store &&
              merch_store.map((item) => (
                <SplideSlide key={item.id}>
                  <div className="store-card">
                    <div className="store-image">
                      <GatsbyImage image={item.image} alt="itinary image" />
                    </div>
                    <div className="store-content">
                      <h3>{item.title}</h3>
                      <a href={item.link}>
                        <i className="ri-shopping-cart-line"></i> Buy Now
                      </a>
                    </div>
                  </div>
                </SplideSlide>
              ))}
          </Splide>
        </div>
      </section>

      <Parallax bgImage={upper_img} bgImageAlt="the cat" strength={300}>
        <div style={{ height: "420px" }} />
      </Parallax>

      <section className="album-section" id="latest-album">
        <div className="container">
          <div className="album-wrapper">
            <div className="title-wp">
              <div className="music-wave">
                <img src={music_wave} alt="music_wave" />
              </div>
              <h2 className="section-title">Latest Album</h2>
              <p>
                Harmonies that Resonate, Lyrics that Illuminate: Dive into the
                Soundscape of our Latest Album.
              </p>
            </div>

            <div className="about-album">
              <div className="album-content">
                <h2 className="section-title">{latestAlbum.title}</h2>

                <p>{latestAlbum.desc}</p>

                <p className="red">{latestAlbum.tag_line}</p>
              </div>
              <div className="album-card">
                <div className="album-img">
                  <GatsbyImage
                    image={latestAlbum.cover.childImageSharp.gatsbyImageData}
                    alt="cover"
                  />
                </div>
                <div className="album-songs-list">
                  <div className="songs-list">
                    <div className="cover">
                      {/* <img src={dafan} alt="album" /> */}
                      <GatsbyImage
                        image={
                          latestAlbum.cover.childImageSharp.gatsbyImageData
                        }
                        alt="cover"
                      />
                    </div>
                    <div className="list-wrap">
                      {latestAlbum.songs.map((item, index) => (
                        <a
                          target="_blank"
                          key={index}
                          href={item.link}
                          className="song-item"
                        >
                          {item.name} · {latestAlbum.title}
                        </a>
                      ))}
                    </div>
                    <div className="links-wrap">
                      <a
                        href={latestAlbum.spotify_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ri-spotify-fill"></i>
                      </a>
                      <a
                        href={latestAlbum.apple_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ri-apple-fill"></i>
                      </a>
                    </div>
                  </div>
                  <div className="list-footer">
                    <p>
                      Aarlon · {latestAlbum.title}{" "}
                      <span>| {formatDate(latestAlbum.date)}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="song-wrap">
            <div className="title-wp">
              <div className="music-wave">
                <img src={music_wave} alt="music_wave" />
              </div>
              <h2 className="section-title">Latest Singles</h2>
            </div>
            <Splide
              options={{
                arrows: false,
                pagination: false,
                gap: "24px",
              }}
              aria-label="React Splide Example"
            >
              {latestSingle &&
                latestSingle.songs.map((item, index) => (
                  <SplideSlide key={index}>
                    <a href={item.link} target="_blank">
                      <div className="song-card">
                        <div className="song-image">
                          <GatsbyImage
                            image={item.cover.childImageSharp.gatsbyImageData}
                            alt="itinary image"
                          />
                        </div>
                        <div className="song-content">
                          <h3>{item.name}</h3>
                        </div>
                      </div>
                    </a>
                  </SplideSlide>
                ))}
            </Splide>
          </div>
        </div>
      </section>

      <section className="tour-section" id="tours">
        <div className="container">
          <div className="tour-wrapper">
            <div className="title-wp">
              <div className="music-wave">
                <img src={music_wave} alt="music_wave" />
              </div>
              <h2 className="section-title">Upcoming Tours</h2>
              <p>
                Embark on Unforgettable Journeys: Where Every Destination
                Becomes a Story.
              </p>
            </div>

            <div className="tour-list">
              {tour &&
                tour.map((item) => (
                  <div className="tour-card" key={item.id}>
                    <div className="date">{item.date}</div>
                    <div className="tour-content">
                      <h3>{item.title}</h3>
                      <h3 className="location">{item.location}</h3>
                    </div>
                    <div className="tour-btn">
                      <a href={item.link} target="_blank">
                        Buy Tickets
                      </a>
                    </div>
                  </div>
                ))}
              {tour.length === 0 && (
                <div className="empty-state">
                  <p>No upcoming tours</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <Parallax
        bgImage={lower_img}
        bgImageAlt="the cat"
        strength={300}
      >
        <section className="buy-us-coffee">
          <div className="wp">
            <h1>Perks for our Global Family</h1>
            <p>
              Buy Us a Coffee and Get Exclusive Access to Our Music,
              Merchandise, and More.
            </p>
            <a href="https://www.buymeacoffee.com/aarlon">
              <img src="https://img.buymeacoffee.com/button-api/?text=Buy us a coffee&emoji=&slug=aarlon&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" />
            </a>
          </div>
        </section>
      </Parallax>

      <section className="contact-section" id="contact">
        <div className="container">
          <div className="contact-wrapper">
            <div className="title-wp">
              <div className="music-wave">
                <img src={music_wave} alt="music_wave" />
              </div>
              <h2 className="section-title">Contact Us</h2>
              <p>
                Reach the Heartbeat of Our Melody: Contact Us and Let the Music
                Begin.
              </p>
            </div>

            <div className="email">
              <a href="mailto:">
                <i className="ri-mail-line"></i> band@aarlon.in
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <IndexPageTemplate data={data} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    merch_store: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "merch-store" }
          is_visible: { eq: true }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            link
            image {
              childImageSharp {
                gatsbyImageData(formats: [WEBP])
              }
            }
          }
        }
      }
    }
    tour: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "tour" }, is_visible: { eq: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            location
            link
            date
          }
        }
      }
    }
    home_data: markdownRemark(
      frontmatter: {
        templateKey: { eq: "index-page" }
        latestSong: { file: { publicURL: {} } }
      }
    ) {
      frontmatter {
        latestSong {
          title
          link
          cover {
            childImageSharp {
              gatsbyImageData(formats: [WEBP])
            }
          }
          file {
            uid
            publicURL
          }
        }
        latestAlbum {
          date
          title
          spotify_link
          apple_link
          desc
          tag_line
          songs {
            name
            link
          }
          cover {
            childImageSharp {
              gatsbyImageData(formats: [WEBP])
            }
          }
        }
        latestSingle {
          songs {
            name
            link
            cover {
              childImageSharp {
                gatsbyImageData(formats: [WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
