import React from "react";
import ReactHowler from "react-howler";
import raf from "raf"; // requestAnimationFrame polyfill

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      seek: 0.0,
      rate: 1,
      isSeeking: false,
      name: props.name,
      src: props.songURL,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleLoopToggle = this.handleLoopToggle.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
    this.handleRate = this.handleRate.bind(this);
  }

  componentWillUnmount() {
    this.clearRAF();
  }

  handleToggle() {
    this.setState({
      playing: !this.state.playing,
    });
  }

  handleOnLoad() {
    this.setState({
      loaded: true,
      duration: this.player.duration(),
    });
  }

  handleOnPlay() {
    this.setState({
      playing: true,
    });
    this.renderSeekPos();
  }

  handleOnEnd() {
    this.setState({
      playing: false,
    });
    this.clearRAF();
  }

  handleStop() {
    this.player.stop();
    this.setState({
      playing: false, // Need to update our local state so we don't immediately invoke autoplay
    });
    this.renderSeekPos();
  }

  handleLoopToggle() {
    this.setState({
      loop: !this.state.loop,
    });
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute,
    });
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true,
    });
  }

  handleMouseUpSeek(e) {
    this.setState({
      isSeeking: false,
    });

    this.player.seek(e.target.value);
  }

  handleSeekingChange(e) {
    this.setState({
      seek: parseFloat(e.target.value),
    });
  }

  renderSeekPos() {
    if (!this.state.isSeeking) {
      this.setState({
        seek: this.player.seek(),
      });
    }
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  handleRate(e) {
    const rate = parseFloat(e.target.value);
    this.player.rate(rate);
    this.setState({ rate });
  }

  clearRAF() {
    raf.cancel(this._raf);
  }

  render() {
    return (
      <div className="audio-player-wrap">
        <ReactHowler
          onLoad={this.handleOnLoad}
          onPlay={this.handleOnPlay}
          onEnd={this.handleOnEnd}
          loop={this.state.loop}
          mute={this.state.mute}
          volume={this.state.volume}
          playing={this.state.playing}
          ref={(ref) => (this.player = ref)}
          src={[this.state.src]}
        />
        <div className="audio-player-title">{this.state.name}</div>
        <div className="progress-bar">
          <input
            className="progress"
            type="range"
            min="0"
            max={this.state.duration ? this.state.duration.toFixed(2) : 0}
            step="any"
            value={this.state.seek}
            onChange={this.handleSeekingChange}
            onMouseDown={this.handleMouseDownSeek}
            onMouseUp={this.handleMouseUpSeek}
          />
        </div>
        <div className="audio-player-time">
          <span className="current-time">
            {this.state.seek ? (this.state.seek / 60).toFixed(2) : "0:00"}
          </span>
          <span className="duration">
            {this.state.duration ? (this.state.duration / 60).toFixed(2) : ""}
          </span>
        </div>
        <div className="audio-player-controls">
          {this.state.playing ? (
            <i onClick={this.handleToggle} className="ri-pause-fill"></i>
          ) : (
            <i onClick={this.handleToggle} className="ri-play-fill"></i>
          )}

          <div className="progress-bar">
            <input
              className="progress volume"
              type="range"
              min="0"
              max="1"
              step=".05"
              value={this.state.volume}
              onChange={(e) =>
                this.setState({ volume: parseFloat(e.target.value) })
              }
            />
          </div>
          {this.state.mute ? (
            <i
              onClick={this.handleMuteToggle}
              className="ri-volume-mute-line"
            ></i>
          ) : (
            <i
              onClick={this.handleMuteToggle}
              className="ri-volume-up-line"
            ></i>
          )}
        </div>
      </div>
    );
  }
}

export default AudioPlayer;
